/*------------------------------------
  Select2
------------------------------------*/

.select2-custom {
  position: relative;

  .select2-custom-hide {
    display: none;
  }

  .select2-container--open {
    right: 0 !important;
    left: auto !important;
  }
}

.select2-dropdown {
  border-color: $select2-dropdown-border-color;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  @include border-radius($custom-select-border-radius);
}

.select2-container .select2-search--inline .select2-search__field,
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .form-control.select2-selection--multiple .select2-selection__choice {
  margin-top: $select2-form-control-selection-choice-margin-y;
}

.select2-container--default .form-control.select2-selection--multiple .select2-search--inline {
  margin-top: $select2-form-control-selection-search-inline-margin-y;
}

.select2-container--default .form-control-lg.select2-selection--multiple .select2-selection__choice {
  margin-top: $select2-form-control-lg-selection-choice-margin-y;
}

.select2-container--open .select2-dropdown--below {
  @include border-radius($custom-select-border-radius);
  border-top: $custom-select-border-width solid $custom-select-border-color;
  margin-top: $select2-dropdown-below-margin-y;
}

.select2-container--default .select2-selection--multiple {
  border-color: $select2-selection-multiple-border-color;
  padding: $select2-selection-multiple-padding-y $select2-selection-multiple-padding-x;

  .select2-selection__choice {
    background-color: $select2-selection-choice-bg-color;
    border-color: $select2-selection-choice-border-color;
  }

  .select2-selection__choice__remove {
    color: $select2-selection-choice-remove-color;
  }
}

.select2-selection--multiple .select2-selection__placeholder {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: $select2-selection-placeholder-padding-x;
  transform: translateY(-50%);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: $input-border-color;
  background-color: $select2-search-field-bg-color;
  padding: $input-padding-y-sm $input-padding-x-sm;
  @include border-radius($input-border-radius);

  &::placeholder {
    color: $input-placeholder-color;
  }
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  border-color: $select2-search-field-focus-border-color;
  box-shadow: $input-focus-box-shadow;
}

.select2-container--default .select2-results__option {
  padding: $select2-results-option-padding;

  &[aria-selected=true] {
    color: $select2-results-option-true-color;
    background-color: $select2-results-option-true-bg-color;
  }

  &:first-child {
    &, &:hover {
      @include border-top-radius($custom-select-border-radius);
    }
  }

  &:last-child {
    &, &:hover {
      @include border-bottom-radius($custom-select-border-radius);
    }
  }
}

.select2-container--default .select2-results__option {
  position: relative;
}

.select2-container--default .select2-results__option[aria-selected=true]::after {
  position: absolute;
  top: 50%;
  right: $select2-results-option-selected-pseudo-right-offset;
  width: $select2-results-option-selected-pseudo-width;
  height: $select2-results-option-selected-pseudo-height;
  background: $select2-results-option-selected-pseudo-bg;
  content: "";
  transform: translateY(-50%);
}

.select2-container--default .select2-results__option--highlighted[aria-selected]:not([aria-selected=true]) {
  color: $select2-results-option-selected-color;
  background-color: $select2-results-option-selected-bg-color;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: $select2-selection-multiple-focus-border-color;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: $select2-search-field-border-color;
}

.select2-selection__placeholder {
  color: $select2-selection__placeholder-color;
}