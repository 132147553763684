/*------------------------------------
  Range Slider
------------------------------------*/

.range-slider {
  height: $range-slider-height;

  .irs {
    height: $range-slider-height;
  }

  .irs-line {
    top: $range-slider-inner-elements-height * 2;
    height: $range-slider-inner-elements-height;
  }

  .irs-line-left {
    left: 0;
    height: $range-slider-inner-elements-height;
    background-color: $range-slider-inner-elements-bg-color;
    @include border-left-radius($range-slider-inner-elements-border-radius);
  }

  .irs-line-mid {
    height: $range-slider-inner-elements-height;
    background-color: $range-slider-inner-elements-bg-color;
  }

  .irs-line-right {
    right: 0;
    height: $range-slider-inner-elements-height;
    background-color: $range-slider-inner-elements-bg-color;
    @include border-right-radius($range-slider-inner-elements-border-radius);
  }

  .irs-bar {
    top: $range-slider-inner-elements-height * 2;
    height: $range-slider-inner-elements-height;
    background-color: $primary;
  }

  .irs-bar-edge {
    top: $range-slider-inner-elements-height * 2;
    left: 0;
    height: $range-slider-inner-elements-height;
    width: $rang-slider-inner-elements-bar-edge-width;
    @include border-left-radius($range-slider-inner-elements-border-radius);
    background-color: $rang-slider-bar-edge-bg-color;
  }

  .irs-slider {
    top: -$range-slider-inner-elements-height;
    width: $range-slider-stats-pointer-width;
    height: $range-slider-stats-pointer-height;
    background-color: $rang-slider-bg-color;
    cursor: pointer;
    @include border-radius($rang-slider-border-radius);
    box-shadow: $range-slider-stats-pointer-box-shadow;

    &.state_hover {
      transform: scale(1.3);
    }
  }

  .irs-from,
  .irs-to,
  .irs-single {
    display: inline-block;
    min-width: $range-slider-inner-elements-width;
    background-color: $rang-slider-bg-color;
    color: $rang-slider-inner-elements-color;
    font-size: $range-slider-inner-elements-text-size;
    text-shadow: none;
    text-align: center;
    @include border-radius($rang-slider-single-element-border-radius);
    box-shadow: $rang-slider-single-element-box-shadow;
    padding: $rang-slider-single-element-padding-y $rang-slider-single-element-padding-x;
  }
}

/* Indicator */
.range-slider-indicator {
  height: $range-slider-indicator-height;

  .irs {
    height: $range-slider-indicator-height;
  }

  &.range-slider-grid {
    height: $range-slider-indicator-grid-height;

    .irs {
      height: $range-slider-indicator-grid-height;
    }
  }

  .irs-line {
    top: $rang-slider-indicator-line-top-offset;
  }

  .irs-bar {
    top: $rang-slider-indicator-bar-top-offset;
  }

  .irs-bar-edge {
    top: $rang-slider-indicator-bar-edge-top-offset;
  }

  .irs-slider {
    top: $rang-slider-indicator-slider-top-offset;
  }
}

/* Grid */
.range-slider-grid {
  height: $range-slider-grid-height;

  .irs {
    height: $range-slider-grid-height;
  }

  .irs-grid {
    height: $range-slider-grid-inner-height;
  }

  .irs-grid-text {
    display: inline-block;
    min-width: $range-slider-inner-elements-width;
    line-height: 1;
    font-size: $range-slider-inner-elements-text-size;
    color: $rang-slider-inner-elements-text-color;
    @include border-radius($range-slider-inner-elements-border-radius);
    padding: $range-slider-inner-elements-height;

    &.current {
      background: $rang-slider-inner-elements-current-bg-color;
      color: $rang-slider-inner-elements-current-color;
    }
  }

  .irs-grid-pol {
    height: $rang-slider-inner-grid-pol-height;
    background-color: $range-slider-inner-elements-bg-color;

    &.small {
      display: none;
    }
  }
}