.facetwp-facet {
    margin-bottom: 0px !important;
}

.facetwp-pager-label {
    display: inline-block;
    margin-right: 12px;
}

.facetwp-page {
    display: inline-block;
    padding: 0px 4px;
    margin-right: 6px;
    cursor: pointer;
}

.facetwp-page.active {
    font-weight: bold;
    cursor: default;
}

.facetwp-loading {
    width: 20px;
    height: 20px;
    background: url(../images/loading.png) no-repeat;
    background-size: 20px 20px;
    -webkit-animation: spin 700ms infinite linear;
    -moz-animation: spin 700ms infinite linear;
    animation: spin 700ms infinite linear;
}

.facetwp-overlay {
    position: absolute;
    background-color: #fff;
    opacity: 0.6;
}

/* Checkboxes */

.facetwp-type-checkboxes .facetwp-depth {
    display: none;
}

.facetwp-type-checkboxes .facetwp-depth.visible {
    display: inherit;
}

.facetwp-checkbox {
    background: url(../images/checkbox.png) 0 50% no-repeat;
    background-size: 14px 14px;
    margin-bottom: 4px;
    padding-left: 20px;
    cursor: pointer;
}

.facetwp-checkbox.checked {
    background-image: url(../images/checkbox-on.png);
}

.facetwp-checkbox.disabled,
.facetwp-radio.disabled {
    opacity: 0.4;
    cursor: default;
}

.facetwp-checkbox .facetwp-expand {
    float: right;
}

/* Radio */

.facetwp-radio {
    background: url(../images/radio.png) 0 50% no-repeat;
    background-size: 14px 14px;
    margin-bottom: 4px;
    padding-left: 20px;
    cursor: pointer;
}

.facetwp-radio.checked {
    background-image: url(../images/radio-on.png);
}

/* Date range */

.flatpickr-clear {
    text-align: center;
    padding: 5px 0;
    cursor: pointer;
}

/* fSelect */

.facetwp-type-fselect .fs-wrap,
.facetwp-type-fselect .fs-dropdown {
    width: 220px;
}


.facetwp-type-fselect .fs-wrap.fs-disabled .fs-option {
    opacity: 0.4;
    cursor: default;
}

/* Hierarchy */

.facetwp-depth {
    margin-left: 12px;
}

.facetwp-link {
    cursor: pointer;
}

.facetwp-link.checked {
    font-weight: bold;
    cursor: default;
}

.facetwp-toggle {
    cursor: pointer;
}

.facetwp-hidden {
    display: none;
}

/* Slider */

.facetwp-slider-wrap {
    padding-bottom: 15px;
}

.facetwp-slider-reset {
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
    padding: 4px 8px;
    cursor: pointer;
}

.noUi-target {
    padding: 0 15px;
}

/* Search */

.facetwp-search-wrap {
    position: relative;
}

.facetwp-search {
    padding-right: 28px;
}

.facetwp-btn {
    top: 0;
    right: 5px;
    line-height: 1;
    position: absolute;
    cursor: pointer;
    opacity: 0.5;
}

.facetwp-btn:before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background: url(../images/icon-search.png) no-repeat;
    background-size: 20px 20px;
}

.facetwp-btn.f-reset:before {
    background: url(../images/icon-close.png) no-repeat;
    background-size: 20px 20px;
}

/* Proximity */

#facetwp-location {
    padding-right: 28px;
}

.location-wrap {
    position: relative;
}

.locate-me {
    top: 0;
    right: 5px;
    line-height: 1;
    position: absolute;
    cursor: pointer;
    opacity: 0.5;
}

.locate-me:before {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background: url(../images/icon-locate.png) no-repeat;
    background-size: 20px 20px;
}

.locate-me.f-loading:before {
    background: url(../images/loading.png) no-repeat;
    background-size: 20px 20px;
    -webkit-animation: spin 700ms infinite linear;
    -moz-animation: spin 700ms infinite linear;
    animation: spin 700ms infinite linear;
}

.locate-me.f-reset:before {
    background: url(../images/icon-close.png) no-repeat;
    background-size: 20px 20px;
}

/* CSS animations */

@-o-keyframes spin {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Selections shortcode */

.facetwp-selections li {
    display: inline-block;
    line-height: 1;
}

.facetwp-selections .facetwp-selection-value {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;

    padding-right: 16px;
    background-image: url(../images/icon-close.png);
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: right center;
}


/* Fselect */

.fs-wrap {
    display: inline-block;
    cursor: pointer;
    line-height: 1;
    width: 200px;
}

.fs-label-wrap {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: default;
      padding: 4px;
}

.fs-label-wrap,
.fs-dropdown {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fs-label-wrap .fs-label {
    padding: 6px 22px 6px 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fs-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333;
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    margin: auto;
}




.fs-dropdown {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ddd;
    width: 100% !important;
    margin-top: -1px;
    z-index: 1000;
}

.fs-dropdown .fs-options {
    max-height: 200px;
    overflow: auto;
}

.fs-search input {
    border: none !important;
    box-shadow: none !important;
    outline: none;
    padding: 10px 10px;
    width: 100%;
}

.fs-option,
.fs-search,
.fs-optgroup-label {
    padding: 6px 8px;
    border-bottom: 1px solid #eee;
    cursor: default;
}

.fs-option:last-child {
    border-bottom: none;
}

.fs-search {
    padding: 0 4px;
}

.fs-option {
    cursor: pointer;
    word-break: break-all;
}

.fs-option.disabled {
    opacity: 0.4;
    cursor: default;
}

.fs-option.hl {
    background-color: #f5f5f5;
}

.fs-wrap.multiple .fs-option {
    position: relative;
      padding: 10px;
    padding-left: 30px;
}

.fs-wrap.multiple .fs-checkbox {
    position: absolute;
    display: block;
    width: 30px;
    top: 0;
    left: 0;
    bottom: 0;
}

.fs-wrap.multiple .fs-option .fs-checkbox i {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #aeaeae;
    border-radius: 2px;
    background-color: #fff;
}

.fs-wrap.multiple .fs-option.selected .fs-checkbox i {
    background-color: rgb(17, 169, 17);
    border-color: transparent;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: center;
}

.fs-optgroup-label {
    font-weight: bold;
    text-align: center;
}


@include media-breakpoint-down(sm) {

  .fs-label {
      position: relative;
      background-color: #fff;
      border: 1px solid #ddd;
      cursor: default;
      width: 320px;
      width:100%;

  }

  .fs-wrap {
      display: inline-block;
      cursor: pointer;
      line-height: 1;
      width: 320px;
      width:100% !important;
      margin-bottom: 1rem !important;
  }

  .fs-label-wrap .fs-label {
      padding: 22px 32px 22px 22px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }

.fs-dropdown, .fs-label-wrap {

  min-width: 320px;
  width:100% !important;
}

  .fs-dropdown {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;

      width: 100% !important;
      margin-bottom: 0.5rem !important;

    margin-top: 5px;
    z-index: 1000;
  }

  .fs-wrap.multiple .fs-option {
      position: relative;
      padding-left: 48px;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;

  }

  .fs-wrap.multiple .fs-checkbox {
      position: absolute;
      display: block;
      width: 30px;
      top: 0;
      left: 0;
      bottom: 0;
  }

  .fs-search {
      padding: 0 0px;

      padding-top: .5rem !important;
      padding-bottom: .5rem !important;

      padding-right: 1rem !important;
      padding-left: 1rem !important;

  }

  .fs-wrap.multiple .fs-option .fs-checkbox i {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #aeaeae;
      border-radius: 2px;
      background-color: #fff;
      margin-left: 0.75rem !important;
  }



 }



.tawcvs-swatches {
  overflow: hidden;
  padding: 5px 0px;
}

.tawcvs-swatches .swatch {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: -1px;
  margin-bottom: -1px;
  cursor: pointer;
  border: 1px solid #7f7f7f;
  position: relative;

  opacity:1;
}

.tawcvs-swatches .swatch.selected {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #000;
  background:#000;
  color:#fff;
  opacity: 1;
}

.tawcvs-swatches .swatch.disabled {
  opacity: 0.1;
}

.tawcvs-swatches .swatch-color {
  text-indent: -9999px;
}

.tawcvs-swatches .swatch-color.selected {
  border-color: transparent;
}

.tawcvs-swatches .swatch-color.selected:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  width: 6px;
  height: 10px;
  display: block;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 8px;
  left: 11px;
}

.tawcvs-swatches .swatch-label {
  background: #fff;
}

.tawcvs-swatches .swatch-image {
  overflow: hidden;
}



.tawcvs-swatches .swatch-color {
  text-indent: -9999px;

  width: 40px;
  height: 40px;
  line-height: 40px;
}

.tawcvs-swatches .swatch-color.selected {
  border-color: transparent;
}



.tawcvs-swatches .swatch-color.selected:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  width: 6px;
  height: 10px;
  display: block;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 10px;
  left: 11px;
}

.tawcvs-swatches .swatch-label {
  background-color: #fff;
}

.tawcvs-swatches .swatch-image {
  overflow: hidden;
}

.facetwp-selection-label {
  display:none;
}

.facetwp-selections ul {
  @extend .ml-0 ;
  @extend .pl-0 ;
}

.facetwp-selection-value {
  @extend .badge ;
  @extend .badge-primary ;
  @extend .mb-1 ;
  @extend .pr-4 ;
  @extend .pl-2 ;
  @extend .text-white ;
}



.facetwp-facet-videos_zoeken .facetwp-search {
  @extend .form-control;
  @extend .rounded;
}


.facetwp-facet-videos_zoeken .facetwp-icon {
  right:20px;
}
