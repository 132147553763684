  // Extra small devices (portrait phones, less than 576px)
  // No media query for `xs` since this is the default in Bootstrap

  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {

    .block-hp-products{
      margin-top:-60px;
    }

    .cbp-item {
      border-bottom:1px solid rgba(46,83,71,0.20);
    }

   }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {

    .block-hp-products{
      margin-top:-100px;
    }

   }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {

    .block-hp-products{
      margin-top:-100px;
    }

  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {

    .block-hp-products{
      margin-top:-100px;
    }

    .cbp-item {
        width: 300px; /* your desire value */
    }

   }
