.video-player-icon {
background: rgba(247, 250, 255, 0.6) !important;

width: 3.25rem !important;
height: 3.25rem !important;
}

.bg-light {

  background: #5BCACA;

}


footer {
  background: #57C1C1;


}

.bg-primary .breadcrumbs a.post {
  color:#fff !important;

}


body {

  letter-spacing: 1px;
}



.breadcrumbs a {
  color:#fff !important;
}

.breadrumbs span {
  color:#fff !important;
}

.facetwp-search-wrap,
.facetwp-type-fselect .fs-wrap, .facetwp-type-fselect .fs-dropdown {

  width:100%!important;
}
